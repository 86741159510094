import { differenceInHours, endOfDay, formatISODuration, isAfter, isBefore, startOfDay } from 'date-fns';

interface ResultType {
    booking: EsavBooking | undefined;
    products: Products;
    ticketDownloads: TicketDownloads;
    refundables: Refundables;
}
type ApiResponseType = ApiSchema['BookingResponse'];
type ParamsType = object;

/**
 * Loads a booking by its id.
 *
 * Based on the type of booking, the result will re-create all its details
 * (offers, passengers, products, vouchers).
 */
export default async function apiLoadEsavBooking(id: string): Promise<ApiResult<ResultType>> {
    try {
        return await callApiGet<ResultType, ApiResponseType, ParamsType>(
            `/bookings/${id}`,

            // Request Params
            {},
            // Transform result
            (response: ApiResponseType) => {
                const products = transformProducts(response);

                return {
                    booking: transformBooking(response),
                    products,
                    ticketDownloads: transformTicketDownloads(response, products),
                    refundables: transformRefundables(response, products),
                } satisfies ResultType;
            },
        );
    }
    catch (error: any) {
        handleError(error);

        return {
            ok: false,
            warnings: [],
            data: {
                booking: undefined,
                products: {} as Products,
                ticketDownloads: {} as TicketDownloads,
                refundables: {} as Refundables,
            } satisfies ResultType,
        };
    }
}

/**
 *
 */
function transformBooking(response: ApiResponseType) {
    return {
        id: response.booking?.id ?? '',
        bookingNo: response.booking?.bookingNo ?? '',
        openAmount: response.booking?.openAmount,
        confirmedPrice: response.booking?.confirmedPrice,
        purchaser: response.booking?.purchaser,
        orderDate: zurichDate(response.booking?.createdOn),
        paymentMethods: response.booking?.paymentMethods?.filter(pm => pm.originalPaymentId === null && pm.state === 'SETTLED').map(pm => pm.paymentMethod ?? '') ?? [],
    } satisfies EsavBooking;
}

/**
 *
 */
function transformProducts(response: ApiResponseType) {
    return response.booking?.bookedOffers?.reduce((acc, o) => {
        if (o.activities?.length) {
            const activity = o.activities[0];

            const productConfig = configProducts
                .filter((pc): pc is ProductConfigActivity => pc.type === 'activity')
                .find(pc => pc.productId === activity?.activityId);

            const passengerRefs = response.booking?.passengers.filter(p => activity?.passengerIds.includes(p.id)).map(p => p.externalRef) ?? [];
            const dateFrom = activity?.validFrom;
            const dateUntil = activity?.validUntil;

            // TODO: Ask backend if they can provide duration the same way as for offers
            const durationInHours = (dateFrom && dateUntil) ? Math.abs(differenceInHours(dateFrom, dateUntil)) : 0;
            const duration = formatISODuration({ hours: durationInHours });

            if (productConfig) {
                acc[productConfig.key] = {
                    key: productConfig?.key,
                    type: productConfig?.type,
                    name: productConfig?.name ?? '',
                    activityId: productConfig?.productId ?? '',
                    dateFrom: zurichDate(dateFrom),
                    dateUntil: zurichDate(dateUntil),
                    duration,
                    passengerRefs,
                    availableFulfillmentOptions: [],
                };
            }
        }

        return acc;
    }, {} as Products) ?? {} as Products;
}

function transformTicketDownloads(response: ApiResponseType, products: Products) {
    return response.booking?.bookedOffers?.reduce((acc, o) => {
        // TODO: Enhance to support non-activities (trips, hotel, etc.)
        if (o.activities?.length) {
            const productConfig = configProducts
                .filter((pc): pc is ProductConfigActivity => pc.type === 'activity')
                .find(pc => pc.productId === o.activities?.[0]?.activityId);

            if (!productConfig) {
                return acc;
            }

            response?.booking?.fulfillments
                ?.filter(f => f.bookingParts?.some(bp => o.activities?.some(a => a.id === bp.id)))
                ?.forEach((fulfillment) => {
                    const activity = o.activities?.find(a => fulfillment.bookingParts?.some(bp => bp.id === a?.id));
                    const passenger = response.booking?.passengers.find(p => activity?.passengerIds.includes(p.id));
                    const product = products[productConfig?.key];
                    let keycard: KeycardType = 'PDF_A4';
                    let keycardReference = '';
                    let canDownload = false;

                    if (!product) {
                        return;
                    }

                    // Downloadable if fulfilled (not refunded) and still in valid timeframe (cant download tickets from past)
                    canDownload = activity?.status === 'FULFILLED' && isBefore(zurichDate(), endOfDay(zurichDate(activity?.validUntil)));

                    const swisspassFulfilmentPart = fulfillment.fulfillmentParts?.find(fp => fp.securityFeatures?.some(sf => sf.type?.type === 'SWISSPASS_REFERENCE'));
                    if (swisspassFulfilmentPart) {
                        keycard = 'SWISSPASS';
                        keycardReference = swisspassFulfilmentPart.controlId ?? '';
                    }
                    else {
                        keycard = 'PDF_A4';
                        keycardReference = fulfillment.fulfillmentParts?.[0]?.controlId ?? '';
                    }

                    if (!acc[productConfig.key]) {
                        acc[productConfig.key] = [];
                    }
                    acc[productConfig.key]!.push({
                        id: fulfillment.id,
                        status: activity?.status ?? 'FULFILLED',
                        canDownload,
                        product,
                        passenger: {
                            ref: passenger?.externalRef ?? '',
                            firstName: passenger?.detail?.firstName ?? '',
                            lastName: passenger?.detail?.lastName ?? '',
                            dateOfBirth: zurichDate(passenger?.dateOfBirth),
                        },
                        keycard,
                        keycardReference,
                        documents: fulfillment.fulfillmentDocuments?.map((d) => {
                            return {
                                download: d.downloadLink ?? '',
                                format: d.format,
                                type: d.type,
                            };
                        }) ?? [],
                    });
                });
        }

        return acc;
    }, {} as TicketDownloads) ?? {} as TicketDownloads;
}

/**
 *
 */
function transformRefundables(response: ApiResponseType, products: Products) {
    return response.booking?.bookedOffers?.reduce((acc, o) => {
        // TODO: Enhance to support non-activities (trips, hotel, etc.)
        if (o.activities?.length) {
            const productConfig = configProducts
                .filter((pc): pc is ProductConfigActivity => pc.type === 'activity')
                .find(pc => pc.productId === o.activities?.[0]?.activityId);

            if (!productConfig) {
                return acc;
            }

            response?.booking?.fulfillments
                ?.filter(f => f.bookingParts?.some(bp => o.activities?.some(a => a.id === bp.id)))
                ?.forEach((fulfillment) => {
                    const activity = o.activities?.find(a => fulfillment.bookingParts?.some(bp => bp.id === a?.id));
                    const passenger = response.booking?.passengers.find(p => activity?.passengerIds.includes(p.id));
                    const product = products[productConfig?.key];
                    let isRefundExpired = false;

                    if (!product) {
                        return;
                    }

                    // For Winter Products, Refund is allowed until 23:59 of the day before the start date
                    if (product.key === 'ski' || product.key === 'swp') {
                        isRefundExpired = isAfter(zurichDate(), startOfDay(zurichDate(activity?.validFrom)));
                    }

                    if (!acc[productConfig.key]) {
                        acc[productConfig.key] = [];
                    }
                    acc[productConfig.key]!.push({
                        id: fulfillment.id,
                        type: 'activity',
                        status: activity?.status ?? 'FULFILLED',
                        product,
                        passenger: {
                            ref: passenger?.externalRef ?? '',
                            firstName: passenger?.detail?.firstName ?? '',
                            lastName: passenger?.detail?.lastName ?? '',
                            dateOfBirth: zurichDate(passenger?.dateOfBirth),
                        },
                        price: activity?.price ?? { amount: 0, currency: 'CHF' },
                        isRefundExpired,
                    } as RefundableProduct);
                });
        }

        if (o.ancillaries?.length) {
            const productGroup = acc[Object.keys(acc)[0] as ProductKey] ?? [];

            response?.booking?.fulfillments
                ?.filter(f => f.bookingParts?.some(bp => o.ancillaries?.some(a => a.id === bp.id)))
                ?.forEach((fulfillment) => {
                    const ancilliary = o.ancillaries?.find(a => fulfillment.bookingParts?.some(bp => bp.id === a?.id));

                    productGroup.push({
                        id: fulfillment.id,
                        type: ancilliary?.type === 'INSURANCE' ? 'insurance' : undefined,
                        name: ancilliary?.summary ?? '',
                        status: ancilliary?.status ?? 'FULFILLED',
                        price: ancilliary?.price ?? { amount: 0, currency: 'CHF' },
                    } as RefundableAncilliary);
                });
        }

        return acc;
    }, {} as Refundables) ?? {} as Refundables;
}
