<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';
import { NuxtLink } from '#components';

const {
    type = 'button',
    priority = 'primary',
    onDark = false,
    nextAnimation = false,
    disabled,
    disabledVisually,
    to,
} = defineProps<{
    type?: 'button' | 'link';
    priority?: 'primary' | 'secondary' | 'secondary2';
    onDark?: boolean;
    disabled?: boolean;
    disabledVisually?: boolean;
    to?: RouteLocationRaw;
    nextAnimation?: boolean;
}>();

defineEmits(['click']);

const element = computed(() => {
    switch (type) {
        case 'link':
            return NuxtLink;
        case 'button':
        default:
            return 'button';
    }
});

const classes = computed(() => {
    const classes = [
        'flex',
        'items-center',
        'gap-x-3',
        'typo-button',
        'rounded-sm',
        'px-4',
        'py-3.5',
        'data-[disabled-visually=true]:cursor-default',
        'data-[disabled=true]:pointer-events-none',
    ];

    if (nextAnimation) {
        classes.push('slide-bg');
    }
    else {
        classes.push('hover:bg-shade-2', 'transition-colors');
    }

    if (priority === 'primary') {
        classes.push(
            'bg-brand-a',
            'text-brand-b',
        );

        if (onDark) {
            classes.push(
                'data-[disabled=true]:bg-shade-6',
                'data-[disabled=true]:text-shade-5',
                'data-[disabled-visually=true]:bg-shade-6',
                'data-[disabled-visually=true]:text-shade-5',
            );
        }
        else {
            classes.push(
                'data-[disabled=true]:bg-brand-a-2',
                'data-[disabled=true]:text-brand-a-3',
                'data-[disabled-visually=true]:bg-brand-a-2',
                'data-[disabled-visually=true]:text-brand-a-3',
            );
        }
    }
    else if (priority === 'secondary') {
        classes.push(
            'bg-white',
            'text-brand-b',
        );

        if (onDark) {
            classes.push(
                'data-[disabled=true]:bg-shade-6',
                'data-[disabled=true]:text-shade-5',
                'data-[disabled-visually=true]:bg-shade-6',
                'data-[disabled-visually=true]:text-shade-5',
            );
        }
        else {
            classes.push(
                'data-[disabled=true]:bg-shade-1',
                'data-[disabled=true]:text-shade-3',
                'data-[disabled-visually=true]:bg-shade-1',
                'data-[disabled-visually=true]:text-pale',
            );
        }
    }
    else if (priority === 'secondary2') {
        classes.push(
            'bg-brand-b',
            'text-white',
            'data-[disabled=true]:bg-shade-1',
            'data-[disabled=true]:text-shade-3',
            'data-[disabled-visually=true]:bg-shade-1',
            'data-[disabled-visually=true]:text-pale',
        );
    }
    return classes;
});
</script>

<template>
    <component
        :is="element"
        :class="classes"
        :disabled="disabled"
        :data-disabled="disabled"
        :data-disabled-visually="disabledVisually"
        :to="to"
        @click="$emit('click')"
    >
        <slot name="prependIcon" />
        <slot />
        <slot name="appendIcon" />
    </component>
</template>
