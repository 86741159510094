/**
 * Esav Store
 * ----------------------------
 *
 */
export const useEsavStore = defineStore('esav', () => {
    /**
     * References to downloads and refundables
     * ------------------------------------------------------------------------------------------
     */

    const products = ref<Products>({});
    const ticketDownloads = ref<TicketDownloads>({});
    const refundables = ref<Refundables>({});
    const mainProduct = computed(() => Object.values(products.value)?.[0]);

    const canDownloadTickets = computed(() => {
        return Object.values(ticketDownloads.value).flat().length > 0
            && Object.values(ticketDownloads.value).flat().some(ticketDownload => ticketDownload.canDownload);
    });

    const canRefund = computed(() => {
        return Object.values(refundables.value).flat().length > 0
            && Object.values(refundables.value).flat().some(refundable => !refundable.isRefundExpired);
    });

    const hasInsurance = computed(() => Object.values(refundables.value).flat().some(refundable => refundable.type === 'insurance'));

    /**
     * Booking
     * ------------------------------------------------------------------------------------------
     */
    const booking = ref<EsavBooking>();

    async function loadBooking(id: string) {
        let ok = false;
        ({
            ok,
            data: {
                booking: booking.value,
                products: products.value,
                ticketDownloads: ticketDownloads.value,
                refundables: refundables.value,
            },
        } = await apiLoadEsavBooking(id));

        return ok;
    }

    /**
     * ------------------------------------------------------------------------------------------
     */

    function reset() {
        booking.value = undefined;
        products.value = {};
        ticketDownloads.value = {};
        refundables.value = {};
    }

    return {
        // Booking
        booking,
        loadBooking,

        products,
        mainProduct,
        ticketDownloads,
        canDownloadTickets,
        refundables,
        canRefund,
        hasInsurance,

        reset,
    };
}, {
    // Esav Booking is not persisted! Reload on every fresh page load
    // persist: storePersist.storePersistOptions,
});
