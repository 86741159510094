/**
 * Track Page View for Analytics
 */
export function analyticsTrackPageView(title: string | undefined, path: string, isPageInitialLoad: boolean = true) {
    try {
        if (!window.gtmReady) {
            return;
        }

        const router = useRouter();
        const runtimeConfig = useRuntimeConfig();
        const { dataLayer } = useScriptGoogleTagManager();

        const referrer = router?.options?.history?.state?.back
            ? window.location.origin + router?.options?.history?.state?.back
            : document.referrer ?? undefined;

        const event = {
            event: 'page_view',
            platform_environment: runtimeConfig.public.gtm.env,
            platform_name: 'top-of-travel',
            platform_interface_version: 'europe',
            page_url: window.location.href,
            page_url_path: window.location.pathname,
            page_url_hostname: window.location.origin,
            page_url_query_string: window.location.search,
            page_id: window.location.pathname.replace(/^\/(de|fr|en)/, ''),
            page_title: title,
            page_language: useNuxtApp().$i18n.locale.value,
            page_referrer: referrer,
            page_initial_load: isPageInitialLoad,
        };
        dataLayer.push(event);
        if (runtimeConfig.public.enableDevHelpers) {
            // eslint-disable-next-line no-console
            console.debug('analyticsTrackPageView', event);
        }
    }
    catch (error) {
        handleError(error, false);
    }
}

/**
 * Track UX Interaction for Analytics
 */
export function analyticsTrackUXInteraction(elementType: string = '', elementText: string = '', elementContext: string = '') {
    try {
        if (!window.gtmReady) {
            return;
        }
        const runtimeConfig = useRuntimeConfig();
        const { dataLayer } = useScriptGoogleTagManager();
        const event = {
            event: 'ux_interaction',
            element_type: elementType,
            element_text: elementText,
            element_context: elementContext,

        };
        dataLayer.push(event);
        if (runtimeConfig.public.enableDevHelpers) {
            // eslint-disable-next-line no-console
            console.debug('analyticsTrackUXInteraction', event);
        }
    }
    catch (error) {
        handleError(error, false);
    }
}

/**
 * Track Errors for Analytics
 */
export function analyticsTrackError(errorCode: string = '', errorElementName: string = '', errorMessage: string = '') {
    try {
        if (!window.gtmReady) {
            return;
        }
        const runtimeConfig = useRuntimeConfig();
        const { dataLayer } = useScriptGoogleTagManager();
        const event = {
            event: 'error_custom',
            error_code: errorCode,
            error_element_name: errorElementName,
            error_message: errorMessage,

        };
        dataLayer.push(event);
        if (runtimeConfig.public.enableDevHelpers) {
            // eslint-disable-next-line no-console
            console.debug('analyticsTrackError', event);
        }
    }
    catch (error) {
        handleError(error, false);
    }
}

/**
 * Track eCommerce Event for Analytics
 */
export function analyticsTrackEcommerce(eventName: string, ecommerce: object = {}) {
    try {
        if (!window.gtmReady) {
            return;
        }
        const runtimeConfig = useRuntimeConfig();
        const { dataLayer } = useScriptGoogleTagManager();
        const event = {
            event: eventName,
            ecommerce,
        };
        dataLayer.push({ ecommerce: null });
        dataLayer.push(event);
        if (runtimeConfig.public.enableDevHelpers) {
            // eslint-disable-next-line no-console
            console.debug('analyticsTrackEcommerce', event);
        }
    }
    catch (error) {
        handleError(error, false);
    }
}
