import type { NitroFetchOptions } from 'nitropack';

// Generic Type of the API result. Data contains the actual data
export interface ApiResult<T> {
    ok: boolean;
    warnings: Warning[];
    data: T;
};

// Base Generic Type of the API response
interface BaseApiResposeType {
    warnings?: ApiSchema['WarningCollection'];
    [key: string]: any;
}

type FetchOptions = NitroFetchOptions<any>;

// Base Generic Type of the request parameters (body or query)
type BaseParamsType = Record<string, any>;

/**
 * Generic helper function to call the TOT Backend API.
 * it standardizes the way how request body or params are passed to the API
 * and how the response is transformed into the desired result.
 *
 * Uses TS generics to define the types of the result, the API response and the request parameters.
 */
export async function callApi<ResultType, ApiResponseType extends BaseApiResposeType, ParamsType extends BaseParamsType>(
    endpoint: string,
    method: 'GET' | 'POST' = 'POST',
    params: ParamsType,
    transform: (response: ApiResponseType) => ResultType,
    options?: FetchOptions,
): Promise<ApiResult<ResultType>> {
    const response = await useNuxtApp().$api<ApiResponseType>(endpoint, {
        method,
        ...(method === 'GET' ? { query: params } : { }),
        ...(method === 'POST' ? { body: params } : { }),
        ...options,
    });

    return {
        ok: true,
        warnings: response.warnings?.warnings ?? [],
        data: transform(response),
    };
}

/**
 * Uses {@link callApi} to send a GET request to the TOT Backend API in a standardized way.
 */
export function callApiGet<ResultType, ApiResponseType extends BaseApiResposeType, ParamsType extends BaseParamsType>(
    endpoint: string,
    params: ParamsType,
    transform: (response: ApiResponseType) => ResultType,
    options?: FetchOptions,
): Promise<ApiResult<ResultType>> {
    return callApi(endpoint, 'GET', params, transform, options);
}

/**
 * Uses {@link callApi} to send a POST request to the TOT Backend API in a standardized way.
 */
export function callApiPost<ResultType, ApiResponseType extends BaseApiResposeType, ParamsType extends BaseParamsType>(
    endpoint: string,
    params: ParamsType,
    transform: (response: ApiResponseType) => ResultType,
    options?: FetchOptions,
): Promise<ApiResult<ResultType>> {
    return callApi(endpoint, 'POST', params, transform, options);
}
